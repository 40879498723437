import React from "react"
import Header from "../components/header/header"
import "../../styles/about.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../../styles/vendors.scss"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import webIcon from "../images/web-color-light.svg"
import IGIcon from "../images/instagram-color-light.svg"

const Vendors = ({ data }) => (
  <Layout>
    <Header />
    <SEO
      title="Vendors | Toronto's Best Wedding & Event Vendors"
      description="Our team of amazing, florists, DJ's and other vendors will help take your event to the next level. We will consult with you and walk you through the wedding planning  process. We work hard for you so that you can enjoy your guests. We have a list of amazing suppliers that we trust and have worked with."
    />
    <div className="vendors-wrapper">
      <div className="vendors-text-wrapper">
        <Fade>
          <h2 className="vendor-sub-heading">The dream team</h2>
          <h1 className="vendor-heading">Our Vendors</h1>
          <p className="vendor-body">
            We here at YesInspired carefully curate a list of amazing local
            vendors that are perfect for you. All YESInspired event vendors are
            hand-picked by us and always deliver the best. Check them out below!
          </p>
        </Fade>
      </div>
      <div className="vendor-image">
        <Img alt="logo" fluid={data.vendorHeader.childImageSharp.fluid} />
      </div>
    </div>
    <div className="vendors-list-section">
      <Fade>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper">
            <h3 className="vendor-component-heading">Nayos</h3>
            <p className="vendor-component-body">
              Nayos has a curated collection of event rentals, which can be
              booked directly through our website. Our rentals include
              everything from flatware to neon signs! We also offer complete
              event design + styling for clients that want that custom
              wow-factor. Nayos proudly offers affordable delivery rates across
              the GTA, as well as set-up/teardown services.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.nayos.ca"
                >
                  nayos.ca
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/heynayos/"
                >
                  @heynayos
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image">
            <Img
              alt="logo"
              className="vendor-component-img"
              fluid={data.vendorImage1.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper flipped-1">
            <h3 className="vendor-component-heading">REIBVN Studios</h3>
            <p className="vendor-component-body">
              I shoot to capture the best moments in people’s lives. The
              emotions and beauty in a person beyond the superficial, and to
              freeze time - even if it's captured in just a single photo. It’s
              been a good 10 years thus far. I’ve explored a variety of styles
              throughout the last decade with a strong focus on portraits,
              lifestyle, fashion, intimate weddings and branding/content
              creation.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.reibvn.com"
                >
                  reibvn.com
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/reibvn/"
                >
                  @REIBVN
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image flipped-2 ">
            <Img
              alt="logo"
              className="vendor-component-img vendor-horizontal-img"
              fluid={data.vendorImage2.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper">
            <h3 className="vendor-component-heading">NH Events</h3>
            <p className="vendor-component-body">
              All of our cakes are only made with the freshest ingredients and
              completely from scratch. Just choose your favorite cake or share
              your ideas with us, we promise to transform your idea into a
              reality. Our wide assortment of scrumptious flavours are sure to
              tempt any taste buds.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.nhevents.ca"
                >
                  nhevents.ca
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/nh_events_mississauga/"
                >
                  @nh_events_mississauga
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage3.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper flipped-1">
            <h3 className="vendor-component-heading">Pink Pamplemousse</h3>
            <p className="vendor-component-body">
              Together is a place to be and our goal is to help you create a
              wedding as the marriage that blossoms from it. We take a hands on
              approach to floral design, choosing flowers that tell your love
              story and fit your wedding aesthetic. Our motto speaks for itself
              "Everyone had a unique story. Let us capture yours." We create
              timeless, elegant and romantic florals for your Special Day. All
              wedding floral packages are custom designed, crafted individually
              for each couple and the vision for their Wedding Day.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.pinkpamplemousse.co"
                >
                  pinkpamplemousse.co
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/pinkpamplemoussefloralstudio/"
                >
                  @pinkpamplemousse
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image flipped-2">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage4.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper">
            <h3 className="vendor-component-heading">Amanda Gulka-Armstrong</h3>
            <p className="vendor-component-body">
              Deep down I am a true romantic who believes in happily ever after.
              I am outgoing, caring, and always have a smile on my face. My
              current & past experiences have prepared me to become comfortable
              speaking in front of groups of any size and always strive to
              combine professionalism with warmth. I'm happy to work with you to
              create the perfect ceremony for the two of you. I believe that
              love takes many different forms and am excited to perform your
              ceremony.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://allseasonsweddings.com/en/wedding-officiants/profile/amanda-gulka-armstrong"
                >
                  allseasonsweddings.com
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/weddingswithamanda/"
                >
                  @weddingswithamanda
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage6.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper flipped-1">
            <h3 className="vendor-component-heading">Solitaire Jewellery</h3>
            <p className="vendor-component-body">
              Solitaire creates a vocabulary for those determined to identify
              and assert themselves on their own terms. Collaborate virtually or
              in-person with artist & designer, Alec Armen.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.solitairejewellery.com"
                >
                  solitairejewellery.com
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/solitaire.alexarmen/"
                >
                  @solitaire.alexarmen
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image flipped-2 ">
            <Img
              className="vendor-component-img vendor-horizontal-img"
              fluid={data.vendorImage7.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper">
            <h3 className="vendor-component-heading">AnnimationsMkUp</h3>
            <p className="vendor-component-body">
              AnnimationsMkUp lives to provide professional, friendly service
              with outstanding results. No more awkward, silent wedding mornings
              with a stranger who does what they want to do instead of what
              makes you feel like you! We believe our brides deserve a pampered,
              relaxed wedding morning, with custom designed luxe bridal looks
              developed by our talented artists with our bride's vision in mind.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.annimationsmkup.com"
                >
                  annimationsmkup.com
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/annimationsmkup/"
                >
                  @annimationsmkup
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage8.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper flipped-1">
            <h3 className="vendor-component-heading">4k Productions</h3>
            <p className="vendor-component-body">
              Client-Tailored Artistry, Professionalism, and Quality are the
              pillars of 4K Production Studio’s event photography and video
              production service. With an endless list of glowing Google
              reviews, 4K’s reputation is built on a superior standard of client
              satisfaction in both the Wedding and Personal event space, as well
              as the Commercial shoot domain.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://4kproductionstudio.com/"
                >
                  4kproductionstudio.com
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/4kproductionstudio/"
                >
                  @4kproductionstudio
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image flipped-2">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage9.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper">
            <h3 className="vendor-component-heading">
              Chapter 1 Wedding & Events
            </h3>
            <p className="vendor-component-body">
              My work is directly influenced by my love for adventure and joyful
              memories. Capturing beautiful landscapes and documenting the
              quality time of my adventures with family and friends are second
              nature to me. I encourage my couples to be fully present and be
              themselves in our engagement sessions and on their wedding day so
              I can capture their raw and authentic spirit. Documenting the joy
              and carefree attitude of being in love truly gives me purpose and
              fulfillment in my work.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.chapter1weddingsandevents.com"
                >
                  chapter1weddingsandevents.com
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/chapter1weddings/"
                >
                  @chapter1weddingsandevents
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage10.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper flipped-1">
            <h3 className="vendor-component-heading">Perpetual Rhythms</h3>
            <p className="vendor-component-body">
              The sole purpose of our brand of DJ Entertainment is to help you
              celebrate life’s moments, be it your wedding, corporate event or
              family function.
              <br></br>
              <br></br>
              Please contact us and we’ll send you our FAB Top 40 Hit List – not
              music, rather, a list of 40 Features, Advantages and Benefits that
              we bring to your event. We can show you how your event can be more
              amazing than you ever thought possible.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.perpetualrhythms.com"
                >
                  perpetualrhythms.com
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image flipped-2">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage11.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper">
            <h3 className="vendor-component-heading">Juniper</h3>
            <p className="vendor-component-body">
              It would be our honour to create a special look for your wedding
              or event or help you express your gratitude with a lavish
              corporate gift or holiday arrangement. Visit our online décor
              flower shop or stop in to see us at our shop on Beach Blvd in
              Hamilton for personalized gifts or decadent bouquets for opulent
              occasions.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.juniperfloralstudio.com"
                >
                  juniperfloralstudio.com
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/juniperfloralstudio/"
                >
                  @juniperfloralstudio
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage5.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper flipped-1">
            <h3 className="vendor-component-heading">
              Durham Catering Services
            </h3>
            <p className="vendor-component-body">
              Every memorable event has to have good food Let us shatter your
              expectations by offering you an eating experience that is both
              unforgettable and extraordinary. We source the freshest
              ingredients possible to bring the most vibrant flavors to the
              plate. We even offer organic catering and other specialties to
              personalize your event menu.
              <br></br>
              <br></br>
              Everything is made fresh on the premises in our catering kitchens.
              Our creativity, culinary experience, event planning expertise and
              attention to detail has made hundreds of corporate and business
              clients satisfied and happy and coming back again and again.
              Renowned for innovative, seasonal menus and meticulous service.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.durhamcateringservices.ca"
                >
                  durhamcateringservices.ca
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/durhamcatering/"
                >
                  @durhamcatering
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image flipped-2">
            <Img
              className="vendor-component-img"
              fluid={data.vendorImage12.childImageSharp.fluid}
            />
          </div>
        </div>
        {/*VENDOR*/}
        <div className="vendor-component">
          <div className="vendor-component-text-wrapper">
            <h3 className="vendor-component-heading">
              Vinci Catering & Event Planning
            </h3>
            <p className="vendor-component-body">
              Vinci Catering & Event Planning offers services for corporate
              lunches, business meetings, holiday parties, social events and
              weddings. Owners Michael and Rosie Vinci have dedicated their life
              to provide excellent catering services for top industries and
              sectors in Toronto and the GTA for the past 25 years.
            </p>
            <ul>
              <li>
                <img
                  className="vendor-icon web-icon"
                  alt="web icon"
                  src={webIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.vincicatering.ca"
                >
                  vincicatering.ca
                </a>
              </li>
              <li>
                <img
                  className="vendor-icon ig-icon"
                  alt="instagram icon"
                  src={IGIcon}
                />
                <a
                  className="vendor-component-link"
                  href="https://www.instagram.com/vincicateringto/"
                >
                  @vincicateringto
                </a>
              </li>
            </ul>
          </div>
          <div className="vendor-component-image">
            <Img
              className="vendor-component-img "
              fluid={data.vendorImage13.childImageSharp.fluid}
            />
          </div>
        </div>
      </Fade>
    </div>
  </Layout>
)

export default Vendors

export const vendorQuerry = graphql`
  query {
    vendorImage1: file(relativePath: { eq: "vendor-image-1.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage2: file(relativePath: { eq: "vendor-image-2.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage3: file(relativePath: { eq: "vendor-image-3.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage4: file(relativePath: { eq: "vendor-image-4.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage5: file(relativePath: { eq: "vendor-image-5.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage6: file(relativePath: { eq: "vendor-image-6.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage7: file(relativePath: { eq: "vendor-image-7.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage8: file(relativePath: { eq: "vendor-image-8.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage9: file(relativePath: { eq: "vendor-image-9.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage10: file(relativePath: { eq: "vendor-image-10.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage11: file(relativePath: { eq: "vendor-image-11.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage12: file(relativePath: { eq: "vendor-image-12.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorImage13: file(relativePath: { eq: "vendor-image-13.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vendorHeader: file(relativePath: { eq: "service-design.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
